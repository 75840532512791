import React from 'react';
import { useSiteMap } from '../../../app/pages/ui/utils/withSiteMap';
import { IFooterEntry } from '../../api/contentful/types';
import { Footer } from '../footer';
import { getFooterPropsFromEntry } from '../footer/getFooterPropsFromEntry';

export interface IFooterEntryProps {
	footer: IFooterEntry;
}

export const PageFooter = ({ footer }: IFooterEntryProps) => {
	const siteMap = useSiteMap();

	const props = getFooterPropsFromEntry(footer, siteMap);

	return <Footer {...props} />;
};
