import React from 'react';
import styled from '@emotion/styled';
import { LanguageNavItem } from '../aside/language-switch/LanguageSwitch';
import { AccessibilityButton } from '../aside/accessibility';
import { TopNavigationList } from '../aside/top/TopNavigationList';
import { SectionsNavigationList } from '../main/sections/SectionsNavigationList';
import { StyledMainNavListItemWrapper } from '../main/StyledNavigationItems';

const StyledOverlayMenuContent = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	font-weight: 600;
	font-size: 16px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	margin-top: 73px;
	button {
		font-weight: 600;
	}
`;

export const OverlayMenuContent = ({ onClick }: { onClick?: () => void }) => (
	<StyledOverlayMenuContent>
		<div>
			<StyledTopNavigationList mobileView onItemClick={onClick} />
			<SectionsNavigationList mobileView onItemClick={onClick} />
		</div>

		<div>
			<AccessibilityButton mobileView />
			<StyledMainNavListItemWrapper>
				<LanguageNavItem mobileView />
			</StyledMainNavListItemWrapper>
		</div>
	</StyledOverlayMenuContent>
);

const StyledTopNavigationList = styled(TopNavigationList)`
	background: #f7f7f7;
`;
