import styled from '@emotion/styled';
import { Button, ITheme } from '../../../../../../ui';
import themed from '../../../../../../ui/themer/themed';
import Responsive from '../../../../../../ui/utils/Responsive';
import { Color } from '../../../../styles/Color';

export const StyledButtonAsLink = styled(Button)<{ open: boolean }, ITheme>`
	height: auto;
	width: max-content;
	line-height: unset;
	padding: 0;

	display: flex;
	justify-content: start;
	align-items: center;
	gap: 2px;

	font-family: inherit;
	border: 0;
	color: ${({ open }) =>
		themed(({ typography }) =>
			open ? typography.link.hover.color : typography.body.color,
		)};

	svg {
		margin: 0;
	}
	background: none;
	box-shadow: none;

	&:hover,
	&:hover:not(:disabled) {
		background: none;
		border: none;
		color: ${themed(({ typography }) => typography.link.hover.color)};
	}
	&:focus-visible {
		border-radius: 2px;
		-webkit-text-decoration: underline;
		text-decoration: underline;
		outline: ${Color.AccentDefault} auto 3px;
	}
	&:active:not(:disabled) {
		box-shadow: none;
		transform: none;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 100%;
		gap: 8px;
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;
