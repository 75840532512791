import React from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, ChevronUpIcon } from '../../../../../../ui';
import { StyledButtonAsLink } from './StyledButtonAsLink';
import { useTranslation } from '../../../../i18n/translate/useTranslation';
import Responsive from '../../../../../../ui/utils/Responsive';

const StyledTitle = styled.div`
	text-align: left;
	white-space: wrap;
	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		max-width: 130px;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		max-width: unset;
	}
`;
interface IMenuItemWithSubItemsDropdownProps {
	title: string;
	open: boolean;
	onClick: (
		e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
	) => void;
}

export const SectionParentNavItem = ({
	title,
	open,
	onClick,
}: IMenuItemWithSubItemsDropdownProps) => {
	const { t } = useTranslation();

	return (
		<StyledButtonAsLink
			onClick={onClick}
			type="button"
			open={open}
			aria-label={t('nav_item_submenu_label', { title })}
			aria-expanded={open}
		>
			<StyledTitle>{title}</StyledTitle>
			{open ? (
				<ChevronUpIcon variation="highlight" />
			) : (
				<ChevronDownIcon variation="highlight" />
			)}
		</StyledButtonAsLink>
	);
};
