import { ISiteSectionEntry } from '../../../shared/api/contentful/types';
import { useLocation } from '../../../shared/router/useLocation';
import { useCommonData } from './CommonDataContext';
import { getCurrentSiteSection } from './utils/getCurrentSiteSection';

export function useCurrentSiteSection(): ISiteSectionEntry | undefined {
	const { siteSections, locale } = useCommonData();

	const location = useLocation();

	return getCurrentSiteSection(siteSections, locale, location);
}
