import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import accountIcon from '../../../../../../../assets/account.svg?reference';
import { SIGN_IN_DIALOG_HASH } from '../../../../../auth/sign-in/ui/SignInDialog';
import { useTranslation } from '../../../../i18n/translate/useTranslation';
import { LinkTextNoDecoration } from '../../../../components/link';
import { useUserStore } from '../../../../../auth/user/domain/stores/useUserStore';
import {
	HelloUserText,
	HelloUserTextShimmer,
} from '../../../../../account/ui/shared/HelloUserText';
import { PassengersPageId } from '../../../../../app/pages/domain/constants/PageId';
import { useAuthStore } from '../../../../../auth/shared/store/useAuthStore';

export const AccountButton = observer(() => {
	const { t } = useTranslation();
	const { isAuthenticated } = useAuthStore();
	const { isLoading } = useUserStore();
	const [authorized, setAuthorized] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setAuthorized(isAuthenticated);
		setLoading(isLoading);
	}, [isAuthenticated, isLoading]);

	if (!authorized) {
		return (
			<LinkTextNoDecoration
				href={SIGN_IN_DIALOG_HASH}
				aria-label={t('sign_in_link')}
			>
				{t('sign_in_link')}
				<img src={accountIcon} alt={t('sign_in_account_label')} />
			</LinkTextNoDecoration>
		);
	}

	if (loading) {
		return (
			<StyledContainer>
				<HelloUserTextShimmer />
			</StyledContainer>
		);
	}

	return (
		<StyledContainer>
			<LinkTextNoDecoration href={`/${PassengersPageId.AccountOverview}`}>
				<HelloUserText />
				<img
					src={accountIcon}
					alt={t('sign_in_account_label')}
					aria-label={t('sign_in_account_label')}
				/>
			</LinkTextNoDecoration>
		</StyledContainer>
	);
});

const StyledContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 4px;
	justify-content: center;
	align-items: center;
`;
