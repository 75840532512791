import { Path } from '@move-frontend/contentful-routes';
import { ILocale } from '../../../../../i18n/locale/models/ILocale';
import { getLocalizedPath } from '../../../../../../app/pages/ui/utils/getLocalizedPath';
import { IEnrichedPageEntry } from '../../../../../../app/pages/domain/models/IEnrichedPageEntry';
import { useCurrentPath } from '../../../../../router/useCurrentPath';

export function useAlternateLocalizedLink(
	page: IEnrichedPageEntry | undefined,
	currentLocale: ILocale,
	targetLocale: ILocale,
) {
	const currentPath = useCurrentPath();
	const { path } = getAlternateLocalizedLinkForPage(
		page,
		currentLocale,
		targetLocale,
		currentPath,
	);

	return getLocalizedPath(path, targetLocale);
}

function getAlternateLocalizedLinkForPage(
	page: IEnrichedPageEntry | undefined,
	currentLocale: ILocale,
	targetLocale: ILocale,
	currentPath: Path,
) {
	const matchedLink = page?.alternateLocalizedPageLinks.find(
		(link) => link.locale.code === targetLocale.code,
	);

	if (matchedLink) return matchedLink;

	return getFallbackLocalizedLink(currentPath, currentLocale, targetLocale);
}

function getFallbackLocalizedLink(
	path: Path,
	currentLocale: ILocale,
	targetLocale: ILocale,
) {
	return {
		path: path.removePrefix(currentLocale.prefix).toString(),
		locale: targetLocale,
	};
}
