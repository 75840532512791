import React, { FC } from 'react';
import Head from 'next/head';
import { IEnrichedPageEntry } from '../../../app/pages/domain/models/IEnrichedPageEntry';
import { useLocales } from '../../i18n/locale/useLocales';
import { ILocale } from '../../i18n/locale/models/ILocale';
import { useAlternateLocalizedLink } from '../navigation/aside/language-switch/utils/useAlternateLocalizedLink';
import { useLocation } from '../../router/useLocation';
import { getAbsoluteUrl } from '../../router/getAbsoluteUrl';

export const AlternateLinks: FC<{ page?: IEnrichedPageEntry }> = ({ page }) => {
	const { currentLocale, alternateLocales } = useLocales();

	return (
		<>
			<CurrentPage locale={currentLocale} />
			{alternateLocales.map((locale) => (
				<AlternatePage
					key={locale.code}
					page={page}
					currentLocale={currentLocale}
					targetLocale={locale}
				/>
			))}
		</>
	);
};

const CurrentPage: FC<{ locale: ILocale }> = ({ locale }) => {
	const { pathname } = useLocation();

	return <AlternateLink locale={locale} path={pathname} />;
};

const AlternatePage: FC<{
	page?: IEnrichedPageEntry;
	currentLocale: ILocale;
	targetLocale: ILocale;
}> = ({ page, currentLocale, targetLocale }) => {
	const path = useAlternateLocalizedLink(page, currentLocale, targetLocale);

	return <AlternateLink locale={targetLocale} path={path} />;
};

const AlternateLink: FC<{ locale: ILocale; path: string }> = ({
	locale,
	path,
}) => {
	const url = getAbsoluteUrl(path);

	return (
		<Head>
			<link rel="alternate" hrefLang={locale.code} href={url} />
		</Head>
	);
};
