import React from 'react';
import Head from 'next/head';
import { IEnrichedPageEntry } from '../../../app/pages/domain/models/IEnrichedPageEntry';
import { useTranslation } from '../../i18n/translate/useTranslation';

export interface IMetaData {
	title?: string;
	description?: string;
}

export interface IPageMetaDataProps {
	page?: IEnrichedPageEntry;
	metaData?: IMetaData;
}
export const PageMetaData = ({ page, metaData }: IPageMetaDataProps) => {
	const { seoTitle, seoDescription } = page?.fields || {};
	const { t } = useTranslation();
	const title = metaData?.title || seoTitle || t('web_meta_site_title_default');
	const description =
		metaData?.description ||
		seoDescription ||
		t('web_meta_site_description_default');

	return (
		<Head>
			{/* Generic meta tags */}
			{title && (
				<>
					<title>{title}</title>
					<meta property="og:title" content={title} />
					<meta name="twitter:title" content={title} />
				</>
			)}
			{description && (
				<>
					<meta name="description" content={description} />
					<meta property="og:description" content={description} />
					<meta name="twitter:description" content={description} />
				</>
			)}
		</Head>
	);
};
