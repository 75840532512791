import React from 'react';
import { IMenuItemEntry } from '@move-frontend/contentful';
import { StyledMainNavListItemWrapper } from '../StyledNavigationItems';
import { PageLink } from '../../../../components';
import { useTranslation } from '../../../../i18n/translate/useTranslation';

export const SectionWithoutSubmenuNavItem = ({
	id,
	item,
}: {
	id: string;
	item: IMenuItemEntry;
}) => {
	const { t } = useTranslation();
	const { title } = item.fields;

	return (
		<StyledMainNavListItemWrapper id={id}>
			<PageLink
				pageId={item?.fields?.page?.fields.id || ''}
				aria-label={t('nav_item_link_to_page_label', { title })}
			>
				{title}
			</PageLink>
		</StyledMainNavListItemWrapper>
	);
};
