import React from 'react';
import { observer } from 'mobx-react';
import { AccessibilityButton } from './accessibility';
import { LanguageSwitch } from './language-switch/LanguageSwitch';
import { TopNavigationList } from './top/TopNavigationList';
import { StyledMainNavigationList } from '../main/MainNavigationList';
import { AccountButton } from './account/AccountButton';
import { StyledMainNavListItemWrapper } from '../main/StyledNavigationItems';

export const AsideNavigationList = observer(() => (
	<StyledMainNavigationList>
		<StyledMainNavListItemWrapper>
			<AccountButton />
		</StyledMainNavListItemWrapper>
		<TopNavigationList />
		<LanguageSwitch />
		<AccessibilityButton />
	</StyledMainNavigationList>
));
