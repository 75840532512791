import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { UnstyledUl } from '../../../../../ui';
import {
	IMenuItemEntry,
	ISiteSectionEntry,
} from '../../../api/contentful/types';
import { useNavigationContext } from '../context/NavigationContext';
import { SectionsNavigationList } from './sections/SectionsNavigationList';
import { AsideNavigationList } from '../aside/AsideNavigationList';
import { BREAKPOINT_SCROLLING_MENU } from '../constants';
import Responsive from '../../../../../ui/utils/Responsive';

interface IMainNavigationItemsProps {
	items: IMenuItemEntry[];
	topSections: ISiteSectionEntry[];
	currentSiteSection: ISiteSectionEntry;
}

export const MainNavigationList = ({
	items,
	topSections,
	currentSiteSection,
}: IMainNavigationItemsProps) => {
	const { setSections, setTopSections, setCurrentTopSection } =
		useNavigationContext();

	useEffect(() => {
		if (items) {
			setSections(items);
		}
	}, [items]);

	useEffect(() => {
		if (topSections) {
			setTopSections(topSections);
		}
	}, [topSections]);

	useEffect(() => {
		if (currentSiteSection) {
			setCurrentTopSection(currentSiteSection);
		}
	}, [currentSiteSection]);

	return (
		<>
			<StyledMainNavigationItemsContainer>
				<SectionsNavigationList mobileView={false} />
				<AsideNavigationList />
			</StyledMainNavigationItemsContainer>
		</>
	);
};

export const StyledMainNavigationItemsContainer = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	@media (${BREAKPOINT_SCROLLING_MENU}) {
		padding: 0;
	}
`;

export const StyledMainNavigationList = styled(UnstyledUl)`
	display: flex;
	align-items: center;
	gap: 16px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		gap: 0;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
	}
`;
