import React from 'react';
import { IMenuItemEntry } from '@move-frontend/contentful';
import {
	NavItemType,
	useNavigationContext,
} from '../../context/NavigationContext';
import { SectionWithSubmenuNavItem } from '../../shared/SectionWithSubmenuNavItem';
import { SectionWithoutSubmenuNavItem } from './SectionWithoutSubmenuNavItem';
import { StyledMainNavigationList } from '../MainNavigationList';
import { ISubMenuItemConfig } from '../../shared/NavigationSubMenu';

interface ISectionsNavigationListProps {
	mobileView?: boolean;
	onItemClick?: () => void;
}
export const SectionsNavigationList = ({
	mobileView = false,
	onItemClick,
}: ISectionsNavigationListProps) => {
	const {
		sections,
		selectedItem,
		isSectionSubMenuOpen,
		handleNavigationItemClick,
	} = useNavigationContext();

	const getSubMenuItems = (menuItem: IMenuItemEntry) =>
		menuItem.fields.subMenuItems;

	const getSubMenuConfigItems = (subMenuItems: IMenuItemEntry[]) =>
		subMenuItems.map((subMenuItem) => ({
			id: subMenuItem.id,
			type: 'page',
			link: '',
			pageId: subMenuItem.fields.page?.fields.id,
			title: subMenuItem.fields.title,
		}));

	return (
		<StyledMainNavigationList>
			{sections.map((section) => {
				const subMenuItems = getSubMenuItems(section) as IMenuItemEntry[];
				if (subMenuItems && subMenuItems.length) {
					const subMenuConfigItems = getSubMenuConfigItems(
						subMenuItems,
					) as ISubMenuItemConfig[];
					return (
						<SectionWithSubmenuNavItem
							key={section.fields.page?.fields.id}
							title={section.fields.title}
							contentWidth="300px"
							relativeSubMenu={mobileView}
							subMenuItems={subMenuConfigItems}
							open={isSectionSubMenuOpen && selectedItem === section}
							onItemClick={onItemClick}
							onClick={() =>
								handleNavigationItemClick({
									type: NavItemType.Section,
									item: section,
								})
							}
						/>
					);
				}
				return (
					<SectionWithoutSubmenuNavItem
						id={section.fields.page?.fields.id || ''}
						key={section.fields.page?.fields.id}
						item={section}
					/>
				);
			})}
		</StyledMainNavigationList>
	);
};
