import { locales } from './constants/locales';
import { useLocale } from './useLocale';

export const useLocales = () => {
	const currentLocale = useLocale();
	const alternateLocales = locales.filter(
		({ code }) => code !== currentLocale.code,
	);

	return {
		currentLocale,
		alternateLocales,
	};
};
