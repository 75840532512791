import { Path } from '@move-frontend/contentful-routes';
import { ISiteSectionEntry } from '../../../../shared/api/contentful/types';
import { ILocale } from '../../../../shared/i18n/locale/models/ILocale';
import { ILocation } from '../../../../shared/router/models/ILocation';
import { customPagePathToPageIdMap } from '../../../pages/domain/constants/customPagePathToPageIdMap';

export function getCurrentSiteSection(
	siteSections: ISiteSectionEntry[],
	locale: ILocale,
	location: ILocation,
) {
	const defaultSiteSection = getDefaultSiteSection(siteSections);

	const currentPath = Path.from(location.pathname).removePrefix(locale.prefix);

	if (currentPath.equals('/')) return defaultSiteSection;

	const siteSectionWithMatchingCustomLandingPage = siteSections.find(
		(siteSection) => hasMatchingCustomLandingPage(siteSection, currentPath),
	);

	if (siteSectionWithMatchingCustomLandingPage)
		return siteSectionWithMatchingCustomLandingPage;

	const siteSectionsWithSlug = siteSections.filter((item) => item.fields.slug);

	const matchingSiteSection = siteSectionsWithSlug.find((item) =>
		currentPath.startsWith(Path.from(item.fields.slug)),
	);

	return matchingSiteSection ?? defaultSiteSection;
}

function getDefaultSiteSection(siteSections: ISiteSectionEntry[]) {
	// We use the first Site Section without slug as default.
	// At the time of writing the Passengers site section is the only site section without a slug,
	// but this might change.
	const siteSectionsWithoutSlug = siteSections.filter(
		(item) => !item.fields.slug,
	);

	return siteSectionsWithoutSlug[0];
}

function hasMatchingCustomLandingPage(
	siteSection: ISiteSectionEntry,
	currentPath: Path,
) {
	const landingPageId = siteSection.fields.landingPage?.fields.id;

	if (!landingPageId) return false;

	const matchingCustomPageEntry = Object.entries(customPagePathToPageIdMap)
		.filter(([path]) => path !== '/') // we should ignore the home page here because it would match any route
		.find(([, id]) => id === landingPageId);

	if (!matchingCustomPageEntry) return false;

	const [customPagePath] = matchingCustomPageEntry;

	return currentPath.startsWith(customPagePath);
}
