/* eslint-disable no-restricted-imports */
import { useRouter } from 'next/router';
import { getCurrentHash } from './utils/getCurrentHash';
import { ILocation } from './models/ILocation';

export function useLocation(): ILocation {
	const router = useRouter();
	const hash = getCurrentHash();

	return {
		pathname: router.asPath,
		query: router.query,
		hash,
	};
}
