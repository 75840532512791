import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link, UnstyledLi, UnstyledUl } from '../../../../../ui';
import Responsive from '../../../../../ui/utils/Responsive';
import themed from '../../../../../ui/themer/themed';
import { theme } from '../../../styles';

export const StyledSubmenu = styled(UnstyledUl)<{
	width: string;
	open: boolean;
	relativeSubMenu: boolean;
}>`
	width: ${({ width }) => width};
	font-weight: 400;

	z-index: 25;
	left: -16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: ${({ relativeSubMenu }) =>
		relativeSubMenu ? 'none' : 'rgba(0, 0, 0, 0.1) 0px 4px 10px'};
	position: ${({ relativeSubMenu }) =>
		relativeSubMenu ? 'static' : 'absolute'};
	top: ${({ relativeSubMenu }) => (relativeSubMenu ? 'unset' : '100%')};

	${({ open }) => dropdown(open)}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 100%;
		gap: 0;
		padding: 0;
		padding-top: ${({ open }) => (open ? '16px' : '0')};
	}
`;
export const StyledMainNavListItemWrapper = styled(UnstyledLi)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-decoration: none;
	position: relative;

	&:focus-visible {
		outline: none !important;
	}

	&:hover {
		cursor: pointer;
	}
	a {
		color: ${themed(({ typography }) => typography.body.color)};
		text-decoration: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		padding: 16px;
		${StyledSubmenu} {
			li:last-child {
				padding-bottom: 0;
			}
		}
	}
`;

export const StyledNavLinkItem = styled(Link)`
	&:focus-visible {
		outline: ${theme.button?.primary?.focus?.outline};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 8px;
	}
`;

export const dropdown = (open: boolean) => css`
	padding: ${open ? '16px' : 0};
	max-height: ${open ? 'fit-content' : 0};
	opacity: ${open ? 1 : 0};
	transform: translateY(${open ? '0' : '-10px'});
	transition: opacity 0.3s ease, transform 0.3s ease, max-height 0.3s ease;
`;
