/* eslint-disable no-restricted-imports */
import { useRouter } from 'next/router';

export function removeHashFromUrl(
	router: ReturnType<typeof useRouter>,
	hash?: string,
) {
	const pathname = router.asPath;
	const hashIndex = pathname.indexOf('#');

	if (hashIndex !== -1 && pathname.slice(hashIndex) === `#${hash}`) {
		router.replace(pathname.split('#')[0], undefined, { shallow: true });
	}
}
