import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { CancelIcon, ITheme, MenuIcon } from '../../../../../ui';
import { NavigationLogo } from '../NavigationLogo';
import { BREAKPOINT_SCROLLING_MENU } from '../constants';
import { Logo } from '../../../components';
import {
	NavItemType,
	useNavigationContext,
} from '../context/NavigationContext';
import { Z_INDEX_MENU_OVERLAY } from '../../../styles';
import { useTranslation } from '../../../i18n/translate/useTranslation';
import { AccountButton } from '../aside/account';
import { FONT_FAMILY_AVENIR_NEXT_LT_PRO } from '../../../styles/Fonts';
import { Color } from '../../../styles/Color';
import Responsive from '../../../../../ui/utils/Responsive';
import themed from '../../../../../ui/themer/themed';

const NavigationLogoWrap = styled.div<{}>`
	flex-grow: 1;

	${Logo} {
		width: auto;
		height: 27px;
	}
`;
interface IStyledOverlayMenuToggleBarProps {
	isSticky: boolean;
	isMenuOpen: boolean;
}
const StyledOverlayMenuToggleBar = styled.div<
	IStyledOverlayMenuToggleBarProps,
	ITheme
>`
	width: 100%;
	display: none;
	position: ${({ isSticky }) => (isSticky ? 'fixed' : 'absolute')};
	${({ isSticky }) =>
		isSticky &&
		css`
			z-index: ${Z_INDEX_MENU_OVERLAY};
		`};
	background: #fff;

	@media (${BREAKPOINT_SCROLLING_MENU}) {
		align-items: center;
		padding: 24px 16px;
		border-bottom: 1px solid #efeff4;
		padding: 24px 16px;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: flex;
		justify-content: space-between;
		top: ${({ isSticky, isMenuOpen }) =>
			isSticky || isMenuOpen ? '0' : 'unset'};
	}
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		top: ${({ isSticky, isMenuOpen }) =>
			isSticky || isMenuOpen ? '0' : 'unset'};
	}
`;

const MenuToggleButton = styled.button<{}, ITheme>`
	padding: 0;
	margin: 0;
	background: none;
	border: none;
	appearance: none;
	outline: none;
	cursor: pointer;
	/* Visually align the svg to the right */
	margin-right: -4px;

	&:focus-visible {
		border-radius: 2px;
		box-shadow: 0 0 0 3px ${Color.AccentDefault};
		text-decoration: underline;
	}

	svg {
		width: 24px;
		height: 24px;
	}
	svg g {
		stroke: ${themed(({ color }) => color.state.hover)};
	}
`;

interface IOverlayMenuToggleBarProps {
	isFixed: boolean;
	isMenuOpen: boolean;
	onClickToggle: () => void;
}

export const OverlayMenuToggleBar = ({
	isFixed,
	isMenuOpen,
	onClickToggle,
}: IOverlayMenuToggleBarProps) => {
	const { t } = useTranslation();
	const { handleNavigationItemClick } = useNavigationContext();
	const handleToggleClick = () => {
		onClickToggle();
		handleNavigationItemClick({ type: NavItemType.Toggle, item: undefined }); // close all submenus
	};
	return (
		<StyledOverlayMenuToggleBar isSticky={isFixed} isMenuOpen={isMenuOpen}>
			<NavigationLogoWrap>
				<NavigationLogo />
			</NavigationLogoWrap>
			<StyledNavMobile>
				<AccountButton />
				<MenuToggleButton
					onClick={handleToggleClick}
					aria-label={
						isMenuOpen
							? t('menu_toggle_button_close_label')
							: t('menu_toggle_button_open_label')
					}
				>
					{isMenuOpen ? (
						<CancelIcon aria-hidden variation="highlight" />
					) : (
						<StyledMenuButton>
							{t('header_top_menu_label')}
							<MenuIcon aria-hidden />
						</StyledMenuButton>
					)}
				</MenuToggleButton>
			</StyledNavMobile>
		</StyledOverlayMenuToggleBar>
	);
};

const StyledNavMobile = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;
const StyledMenuButton = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 18px;
	font-family: ${FONT_FAMILY_AVENIR_NEXT_LT_PRO};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 16px;
	}
`;
