import React from 'react';
import styled from '@emotion/styled';
import { Link } from '../../../../ui';
import { useTranslation } from '../../i18n/translate/useTranslation';
import { Logo } from '../../components';
import { useUrlForPageId } from '../../../app/pages/ui/utils/useUrlForPageId';
import { PassengersPageId } from '../../../app/pages/domain/constants/PageId';

export const StyledNavigationLogo = styled.div`
	margin: 0;
	line-height: 0;

	a {
		display: inline-block;
		padding: 0;
		text-decoration: none;
	}

	img {
		display: block;
	}
`;

export const NavigationLogo = () => {
	const url = useUrlForPageId(PassengersPageId.Home);
	const { t } = useTranslation();

	return (
		<StyledNavigationLogo>
			<Link aria-label={t('header_logo_label')} href={url}>
				<Logo aria-hidden="true" role="img" />
			</Link>
		</StyledNavigationLogo>
	);
};
