import { RefObject, useEffect, useState } from 'react';

interface IArgs extends IntersectionObserverInit {
	freezeOnceVisible?: boolean;
}

function useIntersectionObserver(
	elementRef: RefObject<Element>,
	{
		threshold = 0, // once element is catch, trigger callback
		root = null, // boundaries as for the viewport
		rootMargin = '0px',
		freezeOnceVisible = false,
	}: IArgs = {},
): IntersectionObserverEntry | undefined {
	const [entry, setEntry] = useState<IntersectionObserverEntry>();

	const frozen = entry?.isIntersecting && freezeOnceVisible;

	const updateEntry = ([_entry]: IntersectionObserverEntry[]): void => {
		setEntry(_entry);
	};

	useEffect(() => {
		const node = elementRef?.current;
		const hasIOSupport = !!window.IntersectionObserver;

		if (!hasIOSupport || frozen || !node) return undefined;

		const observerParams = { threshold, root, rootMargin };
		const observer = new IntersectionObserver(updateEntry, observerParams);
		observer.observe(node);

		return () => observer.disconnect();
	}, [
		elementRef?.current,
		JSON.stringify(threshold),
		root,
		rootMargin,
		frozen,
	]);

	return entry;
}

export default useIntersectionObserver;
