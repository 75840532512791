import React, { FC } from 'react';
import styled from '@emotion/styled';
import { locales } from '../../../../i18n/locale';
import { LanguageSwitchItem } from './LanguageSwitchItem';
import LanguageIcon from '../icons/language.svg?reference';
import {
	StyledMainNavListItemWrapper,
	StyledSubmenu,
} from '../../main/StyledNavigationItems';
import {
	NavItemType,
	useNavigationContext,
} from '../../context/NavigationContext';
import { useLocale } from '../../../../i18n/locale/useLocale';
import { StyledButtonAsLink } from '../../main/sections/StyledButtonAsLink';
import { useTranslation } from '../../../../i18n/translate/useTranslation';
import { ITranslationFunction } from '../../../../i18n/translate';
import { Color } from '../../../../styles/Color';

export interface ILanguageSwitchProps {
	className?: string;
}

export const LanguageSwitch: FC<ILanguageSwitchProps> = () => {
	const { isLangSubMenuOpen, handleNavigationItemClick } =
		useNavigationContext();

	if (locales.length <= 1) return null;

	return (
		<StyledMainNavListItemWrapper>
			<LanguageNavItem />

			<StyledSubmenu
				width="max-content"
				open={isLangSubMenuOpen}
				role="list"
				relativeSubMenu={false}
			>
				{isLangSubMenuOpen &&
					locales.map((locale) => (
						<StyledMainNavListItemWrapper
							id={locale.code}
							key={locale.code}
							role="listitem"
							onClick={() =>
								handleNavigationItemClick({
									type: NavItemType.Language,
									item: locale,
								})
							}
						>
							<LanguageSwitchItem locale={locale} />
						</StyledMainNavListItemWrapper>
					))}
			</StyledSubmenu>
		</StyledMainNavListItemWrapper>
	);
};

interface ILanguageNavItemProps {
	mobileView?: boolean;
}
export const LanguageNavItem = ({ mobileView }: ILanguageNavItemProps) => {
	const { t } = useTranslation();
	const currentLocale = useLocale();
	const { handleNavigationItemClick } = useNavigationContext();
	const handleLangClick = () => {
		handleNavigationItemClick({
			type: NavItemType.Language,
			item: undefined,
		});
	};
	return (
		<>
			{mobileView ? (
				<>
					<LanguageSwitchItem
						locale={
							locales.filter((item) => item.code !== currentLocale.code)[0]
						}
					>
						<LanguageImage translation={t} />
					</LanguageSwitchItem>
				</>
			) : (
				<StyledButtonAsLink
					onClick={handleLangClick}
					type="button"
					open={false}
				>
					<LanguageImage translation={t} />
				</StyledButtonAsLink>
			)}
		</>
	);
};
interface ILanguageImageProps {
	translation: ITranslationFunction;
}
const LanguageImage = ({ translation }: ILanguageImageProps) => (
	<StyledImage
		src={LanguageIcon}
		alt={translation('select_website_language_alt')}
		role="button"
		color={Color.PrimaryDefault}
	/>
);
export const StyledImage = styled.img`
	min-width: 24px;
	width: 24px;
	height: 24px;
`;
