import log from 'loglevel';
import {
	BUSINESS_SITE_SECTION_ID,
	CAREERS_SITE_SECTION_ID,
	COMMUNITY_SITE_SECTION_ID,
	NEWS_SITE_SECTION_ID,
	PASSENGERS_SITE_SECTION_ID,
} from '../../../api/contentful/constants';
import { ISiteSectionEntry } from '../../../api/contentful/types';

// Site section order is fixed. Any unknown section will be add at the end.
const orderedSiteSectionIds = [
	PASSENGERS_SITE_SECTION_ID,
	NEWS_SITE_SECTION_ID,
	BUSINESS_SITE_SECTION_ID,
	COMMUNITY_SITE_SECTION_ID,
	CAREERS_SITE_SECTION_ID,
];

export function getOrderedSiteSections(siteSections: ISiteSectionEntry[]) {
	return [...siteSections].sort((a, b) => {
		const indexA = getIndexForSectionId(a.fields.id);
		const indexB = getIndexForSectionId(b.fields.id);

		return indexA - indexB;
	});
}

function getIndexForSectionId(id: string) {
	const index = orderedSiteSectionIds.indexOf(id);

	// Make sure unknown sections will be added last
	if (index === -1) {
		log.warn('Unknown site section ID', id);
		return Infinity;
	}

	return index;
}
