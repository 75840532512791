import { IUrlLinkItem } from '../../../../ui';
import { IFooterAppLinksProps } from './FooterAppLinks';
import {
	IFooterSocialLinksProps,
	ISocialLinkItem,
	ISocialLinkItemPlatform,
} from './FooterSocialLinks';
import { getLinkItemsForEntries } from '../../../content-sections/domain/use-cases';
import { IFooterButtonSectionProps } from './FooterButtonSection';
import { IFooterFinePrintProps } from './FooterFinePrint';
import { ISiteMap } from '../../../app/pages/domain/models/ISiteMap';
import { IFooterEntry } from '../../api/contentful/types';
import {
	IFooterLinkSection,
	IFooterLinkSections,
} from './types/IFooterLinkSection';
import { IFooterProps } from './Footer';

const NUMBER_OF_LINK_SECTIONS = 3;

export function getFooterPropsFromEntry(
	footer: IFooterEntry,
	siteMap: ISiteMap,
): IFooterProps {
	const linkSections = getLinkSections(footer, siteMap);
	const appLinks = getAppLinks(footer);
	const socialLinks = getSocialLinks(footer);
	const buttonSection = getButtonSection(footer);
	const finePrint = getFinePrint(footer, siteMap);

	return {
		linkSections,
		appLinks,
		socialLinks,
		buttonSection,
		finePrint,
	};
}

function getLinkSections(footer: IFooterEntry, siteMap: ISiteMap) {
	const linkSections = [];

	for (let i = 1; i <= NUMBER_OF_LINK_SECTIONS; i += 1) {
		const key = `link-section-${i}`;
		const title = (footer.fields as any)[`linkSection${i}_title`] as string;
		const links = getLinkItemsForEntries(
			(footer.fields as any)[`linkSection${i}_links`],
			siteMap,
		) as IUrlLinkItem[];

		const linkSection: IFooterLinkSection = {
			key,
			title,
			links,
		};

		linkSections.push(linkSection);
	}

	return linkSections as IFooterLinkSections;
}

function getAppLinks(footer: IFooterEntry): IFooterAppLinksProps {
	return {
		title: footer.fields.appLinksSectionTitle,
		appStore: footer.fields.appLinksSectionAppStore
			? footer.fields.appLinksSectionAppStore.fields.url
			: '',
		playStore: footer.fields.appLinksSectionPlayStore
			? footer.fields.appLinksSectionPlayStore.fields.url
			: '',
	};
}

function getSocialLinks(footer: IFooterEntry): IFooterSocialLinksProps {
	const title = footer.fields.socialLinksSectionTitle;
	const links: ISocialLinkItem[] = [];

	Object.values(ISocialLinkItemPlatform).forEach((platform) => {
		const url = (footer.fields as any)[`socialLinksSection${platform}`];

		if (typeof url === 'string') {
			links.push({
				platform,
				url,
			});
		}
	});

	return {
		title,
		links,
	};
}

function getButtonSection({ fields }: IFooterEntry): IFooterButtonSectionProps {
	const buttons = [
		{
			title: fields.newsletterSectionButtonTitle,
			link: fields.newsletterSectionButtonLink,
		},
	];

	if (fields.careersButtonTitle && fields.careersButtonLink) {
		buttons.push({
			title: fields.careersButtonTitle,
			link: fields.careersButtonLink,
		});
	}

	return {
		title: fields.newsletterSectionTitle,
		buttons,
	};
}

function getFinePrint(
	footer: IFooterEntry,
	siteMap: ISiteMap,
): IFooterFinePrintProps {
	const links = footer.fields.finePrintSectionLinks
		? (getLinkItemsForEntries(
				footer.fields.finePrintSectionLinks,
				siteMap,
		  ) as IUrlLinkItem[])
		: [];

	return { links };
}
