import React from 'react';
import { ISubMenuItemConfig, NavigationSubMenu } from './NavigationSubMenu';
import { SectionParentNavItem } from '../main/sections/SectionParentNavItem';
import { StyledMainNavListItemWrapper } from '../main/StyledNavigationItems';

interface ISectionWithSubmenuNavItemProps {
	className?: string;
	contentWidth?: string;
	relativeSubMenu?: boolean;
	open: boolean;
	title: string;
	subMenuItems: ISubMenuItemConfig[];
	onClick: () => void;
	onItemClick?: () => void;
}
export const SectionWithSubmenuNavItem = ({
	className,
	contentWidth = 'max-content',
	relativeSubMenu = false,
	title,
	open,
	subMenuItems,
	onClick,
	onItemClick,
}: ISectionWithSubmenuNavItemProps) => (
	<StyledMainNavListItemWrapper className={className}>
		<SectionParentNavItem title={title} open={open} onClick={onClick} />

		<NavigationSubMenu
			className={className}
			width={contentWidth}
			subMenuItems={subMenuItems}
			open={open}
			relativeSubMenu={relativeSubMenu}
			onItemClick={onItemClick}
		/>
	</StyledMainNavListItemWrapper>
);
