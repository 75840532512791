import React, { useEffect } from 'react';
import { MainNavigationContainer } from './main';
import { OverlayMenu } from './overlay';
import { useCurrentSiteSection } from '../../../app/root/ui/useCurrentSiteSection';
import { ISiteSectionEntry } from '../../api/contentful/types';
import { getOrderedSiteSections } from './helpers/getOrderedSiteSections';
import { IEnrichedPageEntry } from '../../../app/pages/domain/models/IEnrichedPageEntry';
import { NavigationContextProvider } from './context/NavigationContextProvider';
import { useNavigationContext } from './context/NavigationContext';

interface INavigationProps {
	isFixed: boolean;
	siteSections: ISiteSectionEntry[];
	currentPage?: IEnrichedPageEntry;
}

export const Navigation = ({
	isFixed,
	siteSections,
	currentPage,
}: INavigationProps) => {
	const currentSiteSection = useCurrentSiteSection();
	const orderedSiteSections = getOrderedSiteSections(siteSections);
	const { setCurrentPageConfig } = useNavigationContext();

	useEffect(() => {
		if (currentPage) {
			setCurrentPageConfig(currentPage);
		}
	}, [currentPage]);

	return (
		<NavigationContextProvider>
			<OverlayMenu
				isFixed={isFixed}
				siteSections={orderedSiteSections}
				currentSiteSection={currentSiteSection}
			/>

			{currentSiteSection && (
				<MainNavigationContainer
					isFixed={isFixed}
					items={currentSiteSection.fields.menuItems}
					topSections={orderedSiteSections}
					currentSiteSection={currentSiteSection}
				/>
			)}
		</NavigationContextProvider>
	);
};
