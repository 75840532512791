import React from 'react';
import styled from '@emotion/styled';
import { ITheme, Shimmer, ShimmerPlaceholderText } from '../../../../ui';
import { useUserStore } from '../../../auth/user/domain/stores/useUserStore';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import themed from '../../../../ui/themer/themed';

interface IHelloUserTextProps {
	light?: boolean;
}
export const HelloUserText = ({ light = false }: IHelloUserTextProps) => {
	const { t } = useTranslation();
	const { user } = useUserStore();

	return (
		<StyledHelloUserText light={light}>
			{t('user_hello_label', { name: user?.firstName })}
		</StyledHelloUserText>
	);
};

const StyledHelloUserText = styled.span<IHelloUserTextProps, ITheme>`
	color: ${({ light }) =>
		light ? themed(({ color }) => color.text.inverted) : ''};
`;

export const HelloUserTextShimmer = () => (
	<AccountTextShimmer>
		<AccountText />
	</AccountTextShimmer>
);
const AccountText = styled(ShimmerPlaceholderText)`
	display: block;
`;
const AccountTextShimmer = styled(Shimmer)`
	width: 80px;
`;
