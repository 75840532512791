import React from 'react';
import styled from '@emotion/styled';
import {
	StyledMainNavListItemWrapper,
	StyledNavLinkItem,
	StyledSubmenu,
} from '../main/StyledNavigationItems';
import { PageLink } from '../../../components';

export interface ISubMenuItemConfig {
	id: string;
	link: string;
	pageId: string;
	title: string;
	type: 'page' | 'link';
}

interface INavigationSubMenuProps {
	subMenuItems: ISubMenuItemConfig[];
	width: string;
	className?: string;
	open: boolean;
	relativeSubMenu?: boolean;
	onItemClick?: () => void;
}
export const NavigationSubMenu = ({
	width,
	className,
	subMenuItems,
	open,
	relativeSubMenu = false,
	onItemClick,
}: INavigationSubMenuProps) => (
	<StyledSubmenu
		className={className}
		open={open}
		role="list"
		relativeSubMenu={relativeSubMenu}
		width={width}
	>
		{open &&
			subMenuItems.map((subMenuItem) => (
				<StyledMainNavSubMenuListItemWrapper
					id={subMenuItem.id}
					role="listitem"
					key={subMenuItem.id}
					onClick={onItemClick}
				>
					{subMenuItem.type === 'page' ? (
						<PageLink pageId={subMenuItem.pageId}>{subMenuItem.title}</PageLink>
					) : (
						<StyledNavLinkItem href={subMenuItem.link}>
							{subMenuItem.title}
						</StyledNavLinkItem>
					)}
				</StyledMainNavSubMenuListItemWrapper>
			))}
	</StyledSubmenu>
);

const StyledMainNavSubMenuListItemWrapper = styled(
	StyledMainNavListItemWrapper,
)``;
