import { QUICK_LINKS_SECTION_TYPE_ID } from '../../../shared/api/contentful/constants';
import {
	IPageSectionEntry,
	IQuickLinksSectionEntry,
} from '../../../shared/api/contentful/types';
import {
	IMergedQuickLinksSection,
	ISection,
	MERGED_QUICK_LINK_SECTIONS_TYPE_ID,
} from '../models';

/**
 * Merge two subsequent Quick Link Sections into one, so that it can be rendered in a two column layout on desktop.
 */
export function mergeNeighbouringQuickLinkSections(
	sections: IPageSectionEntry[],
) {
	return sections.reduce((previous, section) => {
		// Add non-quick sections
		if (section.contentType !== QUICK_LINKS_SECTION_TYPE_ID) {
			return previous.concat(section);
		}

		// Add a quick link section if the previous section was not a quick link section
		if (
			!previous.length ||
			previous[previous.length - 1].contentType !== QUICK_LINKS_SECTION_TYPE_ID
		) {
			previous.push(section);
			return previous;
		}

		// Otherwise, merge the current quick link section with the previous one
		const other = previous[previous.length - 1] as IQuickLinksSectionEntry;
		const merged: IMergedQuickLinksSection = {
			id: `${other.id}|${section.id}`,
			contentType: MERGED_QUICK_LINK_SECTIONS_TYPE_ID,
			sections: [other, section],
		};

		return previous.slice(0, -1).concat(merged);
	}, [] as ISection[]);
}
