import { isServerSide } from '../../utils/isServerSide';

export function getCurrentHash() {
	if (isServerSide()) {
		return undefined;
	}

	const { hash } = window.location;

	// Remove the # prefix
	return hash.replace(/^#/, '') || undefined;
}
