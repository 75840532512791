import React, { FC, PropsWithChildren, useReducer, useState } from 'react';
import { IMenuItemEntry, ISiteSectionEntry } from '@move-frontend/contentful';
import { ILocale } from '@move-frontend/contentful-routes';
import {
	INavItemProps,
	NavigationContext,
	NavItemType,
	SelectedItemType,
} from './NavigationContext';
import { IEnrichedPageEntry } from '../../../../app/pages/domain/models/IEnrichedPageEntry';

type ActionType =
	| { type: NavItemType.Section; item: IMenuItemEntry }
	| { type: NavItemType.Top; item: ISiteSectionEntry }
	| { type: NavItemType.Language; item: ILocale | undefined }
	| { type: NavItemType.Toggle; item: undefined };

interface INavigationState {
	selectedItem: SelectedItemType;
	isSectionSubMenuOpen: boolean;
	isTopSectionSubMenuOpen: boolean;
	isLangSubMenuOpen: boolean;
}
const initNavigationState: INavigationState = {
	selectedItem: undefined,
	isSectionSubMenuOpen: false,
	isTopSectionSubMenuOpen: false,
	isLangSubMenuOpen: false,
};
export const NavigationContextProvider: FC<PropsWithChildren> = ({
	children,
}) => {
	const [state, dispatch] = useReducer(navigationReducer, initNavigationState);

	const [sections, setSections] = useState<IMenuItemEntry[]>([]);
	const [topSections, setTopSections] = useState<ISiteSectionEntry[]>([]);
	const [currentTopSection, setCurrentTopSection] =
		useState<ISiteSectionEntry>();

	// It's set from Navigation component. It has the current page configuration.
	const [currentPageConfig, setCurrentPageConfig] =
		useState<IEnrichedPageEntry>();

	const {
		selectedItem,
		isTopSectionSubMenuOpen,
		isLangSubMenuOpen,
		isSectionSubMenuOpen,
	} = state;

	const handleNavigationItemClick = ({ type, item }: INavItemProps) => {
		dispatch({ type, item } as ActionType);
	};

	return (
		<NavigationContext.Provider
			/* eslint-disable-next-line react/jsx-no-constructed-context-values */
			value={{
				currentPageConfig,
				setCurrentPageConfig,
				currentTopSection,
				setCurrentTopSection,
				isSectionSubMenuOpen,
				isTopSectionSubMenuOpen,
				isLangSubMenuOpen,
				sections,
				setSections,
				topSections,
				setTopSections,
				selectedItem,
				handleNavigationItemClick,
			}}
		>
			{children}
		</NavigationContext.Provider>
	);
};

const navigationReducer = (
	state: INavigationState,
	action: ActionType,
): INavigationState => {
	switch (action.type) {
		case NavItemType.Section:
			return {
				...state,
				selectedItem: action.item,
				isTopSectionSubMenuOpen: false,
				isLangSubMenuOpen: false,
				isSectionSubMenuOpen:
					!state.isSectionSubMenuOpen ||
					(state.isSectionSubMenuOpen && action.item === state.selectedItem)
						? !state.isSectionSubMenuOpen
						: state.isSectionSubMenuOpen,
			};

		case NavItemType.Top:
			return {
				...state,
				selectedItem: action.item,
				isLangSubMenuOpen: false,
				isSectionSubMenuOpen: false,
				isTopSectionSubMenuOpen: !state.isTopSectionSubMenuOpen,
			};

		case NavItemType.Language:
			return {
				...state,
				selectedItem: action.item,
				isSectionSubMenuOpen: false,
				isTopSectionSubMenuOpen: false,
				isLangSubMenuOpen: !state.isLangSubMenuOpen,
			};

		default:
			return {
				...state,
				selectedItem: action.item,
				isSectionSubMenuOpen: false,
				isTopSectionSubMenuOpen: false,
				isLangSubMenuOpen: false,
			};
	}
};
