import React, { FC, ReactElement } from 'react';
import { useLocale } from '../../../../i18n/locale/useLocale';
import { ILocale } from '../../../../i18n/locale/models/ILocale';
import { useAlternateLocalizedLink } from './utils/useAlternateLocalizedLink';
import { getLocation } from '../../../../router/hash/hashHistory';
import { useTranslation } from '../../../../i18n/translate/useTranslation';
import { useNavigationContext } from '../../context/NavigationContext';
import { StyledNavLinkItem } from '../../main/StyledNavigationItems';

interface ILanguageItemProps {
	locale: ILocale;
	children?: ReactElement | ReactElement[];
}
export const LanguageSwitchItem: FC<ILanguageItemProps> = ({
	locale,
	children,
}) => {
	const { t } = useTranslation();
	const { currentPageConfig } = useNavigationContext();
	const currentLocale = useLocale();
	const { hash: currentHash } = getLocation();
	const href = `${useAlternateLocalizedLink(
		currentPageConfig,
		currentLocale,
		locale,
	)}${currentHash}`;

	return (
		<StyledNavLinkItem href={href}>
			{children && children}
			{locale.displayValue === 'EN'
				? t('main_nav_language_en_label')
				: t('main_nav_language_es_label')}
		</StyledNavLinkItem>
	);
};
