import {
	IExternalLinkEntry,
	IInternalLinkEntry,
} from '../../../../shared/api/contentful/types';
import { EXTERNAL_LINK_TYPE_ID } from '../../../../shared/api/contentful/constants';
import { getUrlForPageId } from './getUrlForPageId';
import { ISiteMap } from '../../domain/models/ISiteMap';

export type MaybeLink = IInternalLinkEntry | IExternalLinkEntry | undefined;

export function getUrlForLink(link: MaybeLink, siteMap: ISiteMap) {
	if (!link) return undefined;

	if (link.contentType === EXTERNAL_LINK_TYPE_ID) {
		return link.fields.url;
	}

	return getUrlForInternalLink(link, siteMap);
}

function getUrlForInternalLink(link: IInternalLinkEntry, siteMap: ISiteMap) {
	let url = getUrlForPageId(link.fields.page?.fields.id || '', siteMap);

	if (link.fields.hash) {
		url += `#${link.fields.hash}`;
	}

	return url;
}
