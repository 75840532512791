import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
	NavItemType,
	useNavigationContext,
} from '../../context/NavigationContext';
import { SectionWithSubmenuNavItem } from '../../shared/SectionWithSubmenuNavItem';
import { ISubMenuItemConfig } from '../../shared/NavigationSubMenu';
import { useTranslation } from '../../../../i18n/translate/useTranslation';

interface ITopNavigationListProps {
	mobileView?: boolean;
	className?: string;
	onItemClick?: () => void;
}

export const TopNavigationList = ({
	mobileView,
	className,
	onItemClick,
}: ITopNavigationListProps) => {
	const { t } = useTranslation();
	const {
		selectedItem,
		topSections,
		currentTopSection,
		isTopSectionSubMenuOpen,
		handleNavigationItemClick,
	} = useNavigationContext();
	const allSections = topSections;
	const [subSections, setSubSections] = useState<ISubMenuItemConfig[]>([]);

	useEffect(() => {
		if (mobileView) {
			handleNavigationItemClick({
				type: NavItemType.Top,
				item: currentTopSection,
			});
		}
	}, [mobileView]);

	useEffect(() => {
		const close = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				handleNavigationItemClick({
					type: NavItemType.Top,
					item: undefined,
				});
			}
		};
		window.addEventListener('keydown', close);
		return () => window.removeEventListener('keydown', close);
	}, []);

	useEffect(() => {
		setSubSections(getSubSections());
	}, [currentTopSection]);

	const getSubSections = () => {
		if (currentTopSection) {
			const SubMenuSections: ISubMenuItemConfig[] = allSections.map(
				(section) => ({
					id: section.id,
					type: 'page',
					link: '',
					pageId: section.fields.landingPage?.fields.id || '',
					title: section.fields.title,
				}),
			);
			return SubMenuSections;
		}
		return [];
	};

	return (
		<>
			{currentTopSection && (
				<SectionWithSubmenuNavItem
					className={className}
					contentWidth="200px"
					relativeSubMenu={mobileView}
					title={t('header_top_menu_label')}
					open={isTopSectionSubMenuOpen && selectedItem === currentTopSection}
					subMenuItems={subSections}
					onItemClick={onItemClick}
					onClick={() =>
						handleNavigationItemClick({
							type: NavItemType.Top,
							item: currentTopSection,
						})
					}
				/>
			)}
			{mobileView && <StyledSeparator />}
		</>
	);
};
const StyledSeparator = styled.div`
	border-bottom: 1px solid #efeff4;
`;
