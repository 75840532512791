import { IMenuItemEntry, ISiteSectionEntry } from '@move-frontend/contentful';
import { ILocale } from '@move-frontend/contentful-routes';
import { createContext, useContext } from 'react';
import { IEnrichedPageEntry } from '../../../../app/pages/domain/models/IEnrichedPageEntry';

export type SelectedItemType =
	| IMenuItemEntry
	| ISiteSectionEntry
	| ILocale
	| undefined;

export enum NavItemType {
	Section = 'section',
	Top = 'top',
	Language = 'language',
	Toggle = 'toggle',
}
export interface INavItemProps {
	type: NavItemType;
	item: SelectedItemType;
}
interface INavigationContext {
	currentPageConfig?: IEnrichedPageEntry;
	setCurrentPageConfig: (item: IEnrichedPageEntry) => void;
	currentTopSection: ISiteSectionEntry | undefined;
	setCurrentTopSection: (item: ISiteSectionEntry) => void;
	selectedItem: SelectedItemType;
	sections: IMenuItemEntry[];
	setSections: (items: IMenuItemEntry[]) => void;
	topSections: ISiteSectionEntry[];
	setTopSections: (items: ISiteSectionEntry[]) => void;
	isSectionSubMenuOpen: boolean;
	isTopSectionSubMenuOpen: boolean;
	isLangSubMenuOpen: boolean;
	handleNavigationItemClick: (props: INavItemProps) => void;
}
const initialNavData: INavigationContext = {
	currentPageConfig: undefined,
	/* eslint-disable @typescript-eslint/no-empty-function */
	setCurrentPageConfig: () => {},
	currentTopSection: undefined,
	setCurrentTopSection: () => {},
	sections: [],
	setSections: () => {},
	topSections: [],
	setTopSections: () => {},
	selectedItem: undefined,
	isSectionSubMenuOpen: false,
	isTopSectionSubMenuOpen: false,
	isLangSubMenuOpen: false,
	handleNavigationItemClick: () => {},
	/* eslint-enable @typescript-eslint/no-empty-function */
};
export const NavigationContext =
	createContext<INavigationContext>(initialNavData);

export const useNavigationContext = () => useContext(NavigationContext);
