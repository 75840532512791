import log from 'loglevel';
import { INTERNAL_LINK_TYPE_ID } from '@move-frontend/contentful';
import {
	IAssetLinkItem,
	IEmailLinkItem,
	IExternalLinkItem,
	IInternalLinkItem,
	IPhoneLinkItem,
} from '../../../../ui';
import { ILinkEntry } from '../../../shared/api/contentful/types';
import {
	ASSET_LINK_TYPE_ID,
	EMAIL_LINK_TYPE_ID,
	EXTERNAL_LINK_TYPE_ID,
	PHONE_LINK_TYPE_ID,
} from '../../../shared/api/contentful/constants';
import { getListedEntryKey } from '../../../shared/utils/getListedEntryKey';
import { ISiteMap } from '../../../app/pages/domain/models/ISiteMap';
import { getUrlForLink } from '../../../app/pages/ui/utils/getUrlForLink';

export function getLinkItemForEntry(
	entry: ILinkEntry,
	index: number,
	siteMap: ISiteMap,
) {
	const key = getListedEntryKey(entry, index);

	switch (entry.contentType) {
		case ASSET_LINK_TYPE_ID:
			if (!entry.fields.asset) {
				return undefined;
			}

			const assetLink: IAssetLinkItem = {
				key,
				type: 'asset-link',
				title: entry.fields.title,
				url: entry.fields.asset.fields.file?.url as string,
			};
			return assetLink;

		case EMAIL_LINK_TYPE_ID:
			const emailLink: IEmailLinkItem = {
				key,
				type: 'email-link',
				...entry.fields,
			};
			return emailLink;

		case EXTERNAL_LINK_TYPE_ID:
			const externalLinkUrl = getUrlForLink(entry, siteMap);

			if (!externalLinkUrl) {
				return undefined;
			}

			const externalLink: IExternalLinkItem = {
				key,
				type: 'external-link',
				title: entry.fields.title,
				url: externalLinkUrl,
			};
			return externalLink;

		case INTERNAL_LINK_TYPE_ID:
			const internalLinkUrl = getUrlForLink(entry, siteMap);

			if (!internalLinkUrl) {
				return undefined;
			}

			const internalLink: IInternalLinkItem = {
				key,
				type: 'internal-link',
				title: entry.fields.title,
				url: internalLinkUrl,
			};
			return internalLink;

		case PHONE_LINK_TYPE_ID:
			const phoneLink: IPhoneLinkItem = {
				key,
				type: 'phone-link',
				...entry.fields,
			};
			return phoneLink;

		default:
			const invalidEntry: never = entry;
			log.error(`Invalid asset link type ${(invalidEntry as any).contentType}`);
	}

	return undefined;
}
