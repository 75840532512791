import React from 'react';
import AccessibilityIcon from '../icons/accessibility.svg';
import { useTranslation } from '../../../../i18n/translate/useTranslation';
import { useUrlForPageId } from '../../../../../app/pages/ui/utils/useUrlForPageId';
import { PassengersPageId } from '../../../../../app/pages/domain/constants/PageId';
import {
	StyledOverlayMenuItemLink,
	StyledOverlayMenuLabel,
} from '../../overlay/StyledOverlayMenuItem';
import { StyledMainNavListItemWrapper } from '../../main/StyledNavigationItems';

interface IAccessibilityButtonProps {
	mobileView?: boolean;
}

export const AccessibilityButton = ({
	mobileView,
}: IAccessibilityButtonProps) => {
	const { t } = useTranslation();
	const url = useUrlForPageId(PassengersPageId.Accessibility);

	return (
		<StyledMainNavListItemWrapper>
			<StyledOverlayMenuItemLink
				href={url}
				aria-label={t('header_accessibility_label')}
			>
				<AccessibilityIcon
					id="accessibility"
					aria-label={t('header_accessibility_label')}
					role="img"
				/>
				{mobileView && (
					<StyledOverlayMenuLabel htmlFor="accessibility">
						{t('header_accessibility_label')}
					</StyledOverlayMenuLabel>
				)}
			</StyledOverlayMenuItemLink>
		</StyledMainNavListItemWrapper>
	);
};
