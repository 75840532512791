import styled from '@emotion/styled';
import { Label, Link } from '../../../../../ui';
import themed from '../../../../../ui/themer/themed';

// the label within menu item
export const StyledOverlayMenuLabel = styled(Label)`
	font-size: 16px;
	line-height: 20px;

	color: ${themed(({ typography }) => typography.body.color)};

	&:hover {
		cursor: pointer;
		color: ${themed(({ typography }) => typography.link.hover.color)};
	}
`;

// the link (includes icon if needed), part of the row in the overlay menu
export const StyledOverlayMenuItemLink = styled(Link)`
	height: 100%;

	display: flex;
	align-items: center;
	gap: 8px;

	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
`;

// the full row in the overlay menu
export const StyledOverlayMenuItem = styled.div`
	display: flex;
	align-items: center;

	height: 56px;

	svg {
		flex-grow: 0;
		flex-shrink: 0;
		height: 24px;
		width: 24px;
	}
`;
