/* eslint-disable no-restricted-imports */
import React, { FC, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Main } from '../../../../ui';
import { css } from '@emotion/core';
import { useRouter } from 'next/router';
import {
	ErrorBoundary,
	NewsAlerts,
	SilentErrorBoundary,
	SolidContentWrap,
} from '../../components';
import { Navigation } from '../navigation';
import { PageFooter } from './PageFooter';
import { useCommonData } from '../../../app/root/ui/CommonDataContext';
import { StyledFooter } from '../footer';
import { getOverlapWithHeaderStyles } from '../header';
import { IMetaData, PageMetaData } from './PageMetaData';
import { IEnrichedPageEntry } from '../../../app/pages/domain/models/IEnrichedPageEntry';
import { AlternateLinks } from './AlternateLinks';
import useIntersectionObserver from '../../utils/useIntersectionObserver';
import { Color } from '../../styles/Color';
import { getCurrentHash } from '../../router/utils/getCurrentHash';
import { removeHashFromUrl } from '../../router/removeHashFromUrl';
import { useTranslation } from '../../i18n/translate/useTranslation';
import Responsive from '../../../../ui/utils/Responsive';

export interface IPageLayoutProps {
	className?: string;
	page?: IEnrichedPageEntry;
	hideFooterOnMobile?: boolean;
	overlapContentWithHeader?: boolean;
	showAlertsDialog?: boolean;
	metaData?: IMetaData;
	children?: React.ReactNode;
}

export const PageLayout: FC<IPageLayoutProps> = ({
	children,
	page,
	showAlertsDialog = false,
	metaData,
	...rest
}) => {
	const { newsAlerts, footer, siteSections } = useCommonData();

	const headerRef = useRef<HTMLDivElement | null>(null);
	const entry = useIntersectionObserver(headerRef);

	const { t } = useTranslation();

	const router = useRouter();

	useEffect(() => {
		removeHashFromUrl(router, getCurrentHash());
	}, [router.asPath]);

	return (
		<>
			<PageMetaData page={page} metaData={metaData} />
			<AlternateLinks page={page} />
			<StyledPage {...rest}>
				<SkipToMainContentLink href="#main">
					{t('skip_to_main_content_link')}
				</SkipToMainContentLink>
				<header ref={headerRef}>
					<NewsAlerts newsAlerts={newsAlerts} showDialog={showAlertsDialog} />
					<Navigation
						siteSections={siteSections}
						currentPage={page}
						isFixed={!entry?.isIntersecting}
					/>
				</header>
				{/* Show an error message when rendering the page content fails. */}
				<ErrorBoundary>
					<Main id="main">{children}</Main>
				</ErrorBoundary>
				{/* Catch errors when rendering the footer fails. */}
				{footer && (
					<SilentErrorBoundary>
						<PageFooter footer={footer} />
					</SilentErrorBoundary>
				)}
			</StyledPage>
		</>
	);
};

const StyledPage = styled.div<{
	hideFooterOnMobile?: boolean;
	overlapContentWithHeader?: boolean;
}>`
	${({ hideFooterOnMobile }) =>
		hideFooterOnMobile &&
		css`
			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				${StyledFooter} {
					display: none;
				}
			}
		`}
	${({ overlapContentWithHeader }) =>
		overlapContentWithHeader &&
		css`
			${SolidContentWrap} {
				${getOverlapWithHeaderStyles()}
			}
		`}
`;

const SkipToMainContentLink = styled.a`
	position: absolute;
	left: -9999px;
	z-index: 999;
	opacity: 0;

	padding: 8px 14px;
	background-color: ${Color.PrimaryDefault};
	border: 2px solid ${Color.BackgroundWhite};
	outline: none;
	color: ${Color.TextWhite};
	border-radius: 4px;
	text-align: center;
	transition: background-color 0.3s ease;

	&:focus {
		left: 260px;
		top: 195px;
		transform: translateX(-50%);
		opacity: 1;
	}

	&:hover {
		background-color: ${Color.PrimaryDark};
		color: ${Color.TextWhite};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		&:focus {
			left: 125px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		&:focus {
			left: 125px;
			top: 185px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		&:focus {
			left: 105px;
			top: 265px;
		}
	}
`;
