import React from 'react';
import styled from '@emotion/styled';
import { ISiteSectionEntry } from '@move-frontend/contentful';
import { css } from '@emotion/core';
import { ITheme } from '../../../../../ui';
import { NavigationLogo, StyledNavigationLogo } from '../NavigationLogo';
import { MainNavigationList } from './MainNavigationList';
import { BREAKPOINT_SCROLLING_MENU } from '../constants';
import { useTranslation } from '../../../i18n/translate/useTranslation';
import { IMenuItemEntry } from '../../../api/contentful/types/IMenuItem';
import { Z_INDEX_MENU_OVERLAY } from '../../../styles';
import {
	DESKTOP_HEADER_HEIGHT,
	MOBILE_HEADER_HEIGHT,
} from '../../../constants';
import Responsive from '../../../../../ui/utils/Responsive';
import ContentWrap from '../../../../../ui/components/Layout/ContentWrap';

interface IMainNavigationProps {
	isFixed: boolean;
	items: IMenuItemEntry[];
	topSections: ISiteSectionEntry[];
	currentSiteSection: ISiteSectionEntry;
}

export const MainNavigationContainer = ({
	isFixed,
	items,
	topSections,
	currentSiteSection,
}: IMainNavigationProps) => {
	const { t } = useTranslation();

	return (
		<StyledMainNavigationWrapper>
			<StyledMainNavigation aria-label={t('main_nav_label')} isSticky={isFixed}>
				<StyledContentWrap>
					<NavigationLogo />
					<MainNavigationList
						items={items}
						topSections={topSections}
						currentSiteSection={currentSiteSection}
					/>
				</StyledContentWrap>
			</StyledMainNavigation>
		</StyledMainNavigationWrapper>
	);
};

interface IStyledMainNavigationProps {
	isSticky: boolean;
}

const StyledMainNavigationWrapper = styled.div`
	height: ${DESKTOP_HEADER_HEIGHT}px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		height: ${MOBILE_HEADER_HEIGHT}px;
	}
`;

const StyledMainNavigation = styled.nav<IStyledMainNavigationProps, ITheme>`
	width: 100%;
	height: 88px;
	padding: 20px 0;

	display: flex;
	align-items: center;

	position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
	top: ${({ isSticky }) => (isSticky ? '0' : 'unset')};
	left: 0;
	right: 0;

	${({ isSticky }) =>
		isSticky &&
		css`
			z-index: ${Z_INDEX_MENU_OVERLAY};
		`};
	background: #fff;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;

	${StyledNavigationLogo} {
		margin: 0 16px 0 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		padding: 0;
		display: none;
		${StyledNavigationLogo} {
			display: none;
		}
	}
`;

const StyledContentWrap = styled(ContentWrap)<{}>`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (${BREAKPOINT_SCROLLING_MENU}) {
		white-space: normal;
		text-align: left;
		margin: 0;
		padding: 0 12px 0 4px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('desktopXL')}) {
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 0 6px;
	}
`;
