import React, { Component } from 'react';
import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import { BREAKPOINT_SCROLLING_MENU } from '../constants';
import { OverlayMenuToggleBar } from './OverlayMenuToggleBar';
import { OverlayMenuContent } from './OverlayMenuContent';
import { Z_INDEX_MENU_OVERLAY } from '../../../styles';
import { ISiteSectionEntry } from '../../../api/contentful/types';
import { IEnrichedPageEntry } from '../../../../app/pages/domain/models/IEnrichedPageEntry';

interface IStyledOverlayProps {
	isMenuOpen: boolean;
}

const StyledOverlay = styled.div<IStyledOverlayProps>`
	display: none;

	@media (${BREAKPOINT_SCROLLING_MENU}) {
		display: block;

		${({ isMenuOpen }) =>
			isMenuOpen
				? `
			display: flex;
			flex-direction: column;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			z-index: ${Z_INDEX_MENU_OVERLAY};
		`
				: ''}
	}
`;

const GlobalMenuStateStyle = ({ isMenuOpen }: IStyledOverlayProps) => {
	const styles = isMenuOpen
		? css`
				@media (${BREAKPOINT_SCROLLING_MENU}) {
					html,
					body {
						overflow: hidden;
						position: fixed;
					}
				}
		  `
		: '';

	return <Global styles={styles} />;
};

interface IOverlayNavigationProps {
	isFixed: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	siteSections: ISiteSectionEntry[];
	// eslint-disable-next-line react/no-unused-prop-types
	currentSiteSection?: ISiteSectionEntry;
	// eslint-disable-next-line react/no-unused-prop-types
	currentPage?: IEnrichedPageEntry;
}

interface IOverlayNavigationState {
	isMenuOpen: boolean;
}

export class OverlayMenu extends Component<
	IOverlayNavigationProps,
	IOverlayNavigationState
> {
	public state = {
		isMenuOpen: false,
	};

	private onClickToggle = () => {
		this.setState(({ isMenuOpen }) => ({ isMenuOpen: !isMenuOpen }));
	};

	public render() {
		const { isFixed } = this.props;
		const { isMenuOpen } = this.state;

		return (
			<StyledOverlay isMenuOpen={isMenuOpen}>
				<GlobalMenuStateStyle isMenuOpen={isMenuOpen} />
				<OverlayMenuToggleBar
					isFixed={isFixed}
					isMenuOpen={isMenuOpen}
					onClickToggle={this.onClickToggle}
				/>
				{isMenuOpen && <OverlayMenuContent onClick={this.onClickToggle} />}
			</StyledOverlay>
		);
	}
}
